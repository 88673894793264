<template>
  <v-sheet class="elevation-3 pa-5">

    <form-product></form-product>
    <list-products></list-products>

  </v-sheet>

</template>

<script>

import List from '@/components/products/List'
import Form from "@/components/products/Form";

export default {

  name: "List",

  data() {
    return {
      search: '',
      openFilters: false,
    }
  },

  components: {
    'list-products': List,
    'form-product': Form
  }
}
</script>

<style lang="scss" scoped>
</style>
