<template>
  <div>

    <v-expansion-panels class="my-3">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Filtra
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="6">
              <input-text label="SKU CODE"
                          :value.sync="find.sku"
                          :clearable="true"
                          @clickclear="getProducts"
              ></input-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="filterBySku">cerca</v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>


    <v-data-table
        :loading="isLoading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="products"
        :items-per-page="pagination.per_page"
        :hide-default-footer="true"
        class="elevation-0 clickable"
        disable-pagination
    >

      <template v-slot:item.name="{ item }">
        <b>{{ item.name }}</b>
      </template>

      <template v-slot:item.image="{ item }">
        <v-img
            v-if="item.image"
            :src="item.image.url"
            contain
            width="100"
            class="py-5"
        />
      </template>

      <template v-slot:item.translations="{ item }">
        <template v-for="l in item.translations">
          <span :key="l" class="ml-3">
            <lang-flag :key="l" :iso="l"/>
          </span>
        </template>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editProduct(item)"
        >
          mdi-pencil
        </v-icon>
      </template>

    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="5"
          @input="getProducts()"
          :disabled="isLoading"
      ></v-pagination>
    </div>

  </div>
</template>

<script>

import {mapGetters} from "vuex";
import {EventBus} from "@/event-bus";
import InputText from "@/components/form/InputText";
import LangFlag from 'vue-lang-code-flags'

export default {
  name: "List",

  data() {
    return {
      find: {},
      headers: [
        {text: '#', value: 'id', sortable: false, width: '5%'},
        {text: this.$t('products.fields.sku_code'), value: 'sku_code', sortable: false, width: '8%'},
        {text: this.$t('products.fields.title'), value: 'title', sortable: false},
        {text: this.$t('products.fields.category'), value: 'category', sortable: false, width: '8%'},
        {text: this.$t('products.fields.position'), value: 'position', sortable: false, width: '8%'},
        {text: this.$t('products.fields.value'), value: 'value', sortable: false, width: '8%'},
        {text: this.$t('products.fields.image'), value: 'image', sortable: false, width: '180px'},
        {text: this.$t('commons.fields.translations'), value: 'translations', sortable: false, width: '12%'},
        {text: '', value: 'actions', sortable: false, width: '5%'},
      ]
    }
  },

  components: {
    InputText,
    LangFlag
  },

  mounted() {
    this.getProducts()
  },

  methods: {
    getProducts() {
      this.$store.dispatch('product/fetchProducts', {
        find: this.find
      })
    },

    editProduct(product) {
      console.log('edit product ', product)
      this.isEditing = true
      EventBus.$emit('edit-product', product)
      this.dialog = true
    },

    filterBySku() {
      this.$store.dispatch('product/fetchProducts', {
        find: this.find
      })
    }

  },

  computed: {
    ...mapGetters({
      isLoading: 'product/loadingState',
      products: 'product/getProducts',
      pagination: 'product/getPagination'
    }),
  }
}
</script>

<style scoped>

</style>
