<template>
  <v-text-field
      v-model="localModel"
      :rules="requiredRules"
      :label="label"
      :required="required"
      :outlined="outlined"
      :dense="dense"
      :clearable="clearable"
      @click:clear="clear"
  ></v-text-field>
</template>

<script>
export default {
name: "InputText",

  props: {
    value: String,
    label: String,
    required: {
      type: Boolean,
      default: false
    },
    requiredRules: Array,
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
  },

  methods: {
  clear() {
    this.localModel = ''
    this.$emit('clickclear')
  }
  },

  computed: {
    localModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      }
    }
  }
}
</script>

<style scoped>

</style>