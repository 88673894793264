var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',{staticClass:"my-3"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Filtra ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('input-text',{attrs:{"label":"SKU CODE","value":_vm.find.sku,"clearable":true},on:{"update:value":function($event){return _vm.$set(_vm.find, "sku", $event)},"clickclear":_vm.getProducts}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{on:{"click":_vm.filterBySku}},[_vm._v("cerca")])],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0 clickable",attrs:{"loading":_vm.isLoading,"loading-text":_vm.$t('commons.loading'),"headers":_vm.headers,"items":_vm.products,"items-per-page":_vm.pagination.per_page,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.name))])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.image)?_c('v-img',{staticClass:"py-5",attrs:{"src":item.image.url,"contain":"","width":"100"}}):_vm._e()]}},{key:"item.translations",fn:function(ref){
var item = ref.item;
return [_vm._l((item.translations),function(l){return [_c('span',{key:l,staticClass:"ml-3"},[_c('lang-flag',{key:l,attrs:{"iso":l}})],1)]})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editProduct(item)}}},[_vm._v(" mdi-pencil ")])]}}])}),_c('v-divider'),_c('div',{staticClass:"text-center py-3"},[_c('v-pagination',{attrs:{"length":_vm.pagination.total,"total-visible":5,"disabled":_vm.isLoading},on:{"input":function($event){return _vm.getProducts()}},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }