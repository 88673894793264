<template>
    <v-dialog
        v-model="dialog"
        max-width="750px"
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            :disabled="isLoading"
        >
          {{$t('commons.actions.new')}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            v-if="product"
        >
          <v-container>
            <v-row>
              <v-col cols="4">
                <input-field
                    v-model="product.sku_code"
                    :rules="requiredRules"
                    :label="$t('products.fields.sku_code')"
                    required
                    outlined
                    dense
                ></input-field>
              </v-col>

              <v-col cols="6">
                <input-field
                    v-model="product.ecommerce_link"
                    :label="$t('products.fields.ecommerce_link')"
                    outlined
                    dense
                ></input-field>
              </v-col>

              <v-col cols="2">
                <input-field
                    v-model="product.position"
                    :label="$t('products.fields.position')"
                    outlined
                    dense
                ></input-field>
              </v-col>
            </v-row>

            <divider></divider>

            <v-row>
              <v-col>
                <v-tabs
                    v-model="langTab"
                    background-color="white"
                >
                  <v-tab
                      fixed-tabs
                      hide-slider
                      v-for="lang in locales"
                      :key="lang"
                  >
                    {{ $t('commons.languages.' + lang) }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="langTab">
                  <v-tab-item
                      v-for="lang in locales"
                      :key="lang"
                      :transition="false"
                      :reverse-transition="false"
                  >
                    <v-card flat v-if="product && product[lang]">
                      <v-card-text>
                        <v-row>
                          <v-col cols="8">
                            <v-row>
                          <v-col cols="8">
                            <input-field
                                v-model="product[lang].title"
                                :rules="(!hasTranslation()) ? requiredRules : []"
                                :label="$t('products.fields.title')"
                                required
                                outlined
                                dense
                            ></input-field>

                          </v-col>
                          <v-col cols="4">
                            <input-field
                                v-model="product[lang].value"
                                :label="$t('products.fields.value')"
                                outlined
                                dense
                            ></input-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                                v-model="product[lang].text"
                                :label="$t('products.fields.text')"
                                outlined
                                dense
                                rows="3"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                          </v-col>
                          <v-col cols="4">


                          <v-row>
                          <v-col>
                            <image-upload id="product_image"
                                          :image.sync="product[lang].image"
                                          :required-rules="(product[lang].title) ? requiredRules : []"
                                          :required="true"
                                          btn-label="image"
                                          :label="$t('products.fields.image')"
                            ></image-upload>

                          </v-col>
                          </v-row>
                          </v-col>
                        </v-row>

                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>


            <v-divider></v-divider>

            <v-row>
              <v-col>
                <dialog-buttons
                    :valid="valid"
                    :is-loading="isLoading"
                    @save="storeProduct"
                    @close="close"
                ></dialog-buttons>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
</template>

<script>

  import clonedeep from 'lodash.clonedeep'
  import DialogButtons from "@/components/form/DialogButtons";
  import ImageUpload from "@/components/form/ImageUpload";
  import {mapGetters} from "vuex";
  import {EventBus} from "@/event-bus";

  export default {
    name: "Form",

    components: {DialogButtons, ImageUpload},

    data: (self) => ({
      langTab: "it",
      product: {},
      isEditing: false,
      dialog: false,
      sending: false,
      valid: false,
      requiredRules: [
        v => (v !== '' || !!v) || self.$t('commons.errors.validation.required'),
      ],
    }),

    mounted() {

      let self = this

      this.product = clonedeep(this.defaultProduct)

      EventBus.$on('edit-product', function (product) {
        self.product = JSON.parse(JSON.stringify(product))
        self.isEditing = true
        self.dialog = true
      })

      EventBus.$on('upload-complete', function (event) {
        console.log('event upload-complete ' , event)
        EventBus.$emit('close-image-dialog')
        self.product.image = event.res
      })

    },

    computed: {
      ...mapGetters({
        locales: 'product/getLocales',
        isLoading: 'product/loadingState'
      }),

      defaultProduct() {
        let localesBodyDefault = {
          title: '',
          text: '',
          value: 0,
          image: {
            id: ''
          }
        }

        let defaultProduct = {
          sku_code: '',
          ecommerce_link: '',
          position: 0
        }

        for (let i = 0; i < this.locales.length; i++)
        {
          defaultProduct[this.locales[i]] = clonedeep(localesBodyDefault)
        }

        return defaultProduct
      },

      formTitle () {
        return (this.isEditing === true) ? this.$t('products.edit.title') : this.$t('products.create.title')
      }

    },

    methods: {
      openImageDialog() {
        EventBus.$emit('open-image-dialog', 'product', 'product')
      },

      hasTranslation() {
        for (let i = 0; i < this.locales.length; i++)
        {
          if (this.product[this.locales[i]] && this.product[this.locales[i]].title !== '') {
            return true
          }
        }
        return false
      },

      async storeProduct() {
        const valid = await this.$refs.form.validate()

        if (valid) {
          if (!this.isEditing) {
            await this.$store.dispatch('product/store', this.product)
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          } else {
            await this.$store.dispatch('product/update', this.product)
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          }
        }
      },

      close () {
        this.dialog = false
        this.valid = true
        this.$refs.form.reset()
        this.$nextTick(() => {
          this.isEditing = false
          this.product = clonedeep(this.defaultProduct)
        })
      },
    }
  }
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}

</style>